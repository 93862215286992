<div class="simple-header justify-content-center">
  <img src="assets/images/logo.svg" class="simple-header-logo" alt="" />
</div>
<div class="simple-page-content no-bottom bg-grey">
  <div class="page-wrapper">
    <div class="bs-xs-40 bs-sm-60"></div>
    <div class="row">
      <div class="col-lg-8">
        <div class="item-simple">
          @if(requestError){
          <div class="msg-item error-msg">
            <img src="assets/icons/info.svg" alt="" />
            <h6 class="ms-3">
              {{ requestError }}
            </h6>
          </div>
          <div class="bs-xs-20"></div>
          } @if (paymentData?.public_key && paymentData?.secret) {
          <app-stripe-form
            [public_key]="paymentData.public_key"
            [secret]="paymentData.secret"
          />
          }
          <div [class.loading]="isLoading">
            <app-loader class="inner-loader" />
          </div>
        </div>
        <div class="bs-xs-20"></div>
      </div>
      <div class="col-lg-4">
        @if (product) {
        <app-product-card
          [item]="product"
          [total]="paymentData?.total"
          [subTotal]="paymentData?.subtotal"
          [tax]="paymentData?.tax"
          [discount]="paymentData?.discount"
        ></app-product-card>
        }
        <div class="bs-xs-20"></div>
        <div class="item-simple item-shadow">
          <h5 class="mb-2">Enter promocode</h5>
          <app-order-promocode
            [code]="salesPromocode"
            [disabled]="isLoading"
            (apply)="applyPromoCode($event)"
            (remove)="removePromoCode($event)"
          />
        </div>
      </div>
    </div>
    <div class="bs-xs-40 bs-sm-60"></div>
  </div>
  <app-step-contact-item />
</div>
<div [class.loading]="isLoading">
  <app-loader class="inner-loader" />
</div>
