import { inject } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHandlerFn
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../../../core/services/auth.service';
import { APP_SESSION_HAS_EXPIRED } from '../../../core/constants/config';
import { environment } from '../../../../environments/environment';

export function AuthInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const Auth = inject(AuthService);
  return next(addAuthToken(req, Auth.getToken())).pipe(
    tap(response => {
      if (response instanceof HttpResponse && response.headers) {
        const token = response.headers.get('Authorization');
        if (token) {
          Auth.setSession(token);
        }
      }
    }),
    catchError((err: HttpErrorResponse) => {
      if (err.status === 401) {
        err.error.message = APP_SESSION_HAS_EXPIRED;
        Auth.clearSession();
        return throwError(() => err);
      }
      return throwError(() => err);
    })
  );
}

function addAuthToken(
  request: HttpRequest<any>,
  token: string
): HttpRequest<any> {
  if (!token || !request.url.startsWith(environment.APP_ENDPOINT)) {
    return request;
  }
  return request.clone({
    setHeaders: {
      Authorization: token
    }
  });
}
