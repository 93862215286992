import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withInMemoryScrolling,
  withViewTransitions
} from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { register } from 'swiper/element/bundle';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './pages/auth/http-interceptors/auth-interceptor';
import {
  provideClientHydration,
  withNoHttpTransferCache
} from '@angular/platform-browser';

register();

export const appConfig: ApplicationConfig = {
  providers: [
    MomentDateAdapter,
    provideEnvironmentNgxMask(),
    provideHttpClient(withInterceptors([AuthInterceptor])),
    provideRouter(
      routes,
      withViewTransitions(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      })
    ),
    provideAnimations(),
    provideClientHydration(withNoHttpTransferCache())
  ]
};
