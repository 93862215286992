import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { StepContactItemComponent } from '../../survey/components/step-contact-item/step-contact-item.component';
import { LoaderComponent } from '../../../shared/components/general/loader/loader.component';
import { ProductApiService } from 'src/app/core/services/product-api.service';
import { ProductCardComponent } from '../../survey/components/product-card/product-card.component';
import { OrderPromocodeComponent } from '../../survey/components/order-promocode/order-promocode.component';
import { finalize } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StripeFormComponent } from '../../../shared/components/form/stripe-form/stripe-form.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-checkout',
  standalone: true,
  templateUrl: './checkout.component.html',
  imports: [
    StepContactItemComponent,
    LoaderComponent,
    ProductCardComponent,
    OrderPromocodeComponent,
    StripeFormComponent
  ]
})
export class CheckoutComponent implements OnInit, OnDestroy {
  public productApiService = inject(ProductApiService);
  private route = inject(ActivatedRoute);
  isLoading = false;
  stripCheckoutForm: any;
  promocode!: string | null;
  salesPromocode!: string | null;
  requestError!: string | null;
  paymentData: any;
  product: any;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    const body = this.document.getElementsByTagName('body')[0];
    body.classList.add('hide-legitscript');
    this.getProduct();
  }

  ngOnDestroy(): void {
    const body = this.document.getElementsByTagName('body')[0];
    body.classList.remove('hide-legitscript');
  }

  getProduct() {
    const productId = this.route.snapshot.queryParamMap.get('product_id');
    const dose = this.route.snapshot.queryParamMap.get('dose');
    this.salesPromocode = this.route.snapshot.queryParamMap.get('promocode');
    if (this.salesPromocode) {
      this.promocode = this.salesPromocode;
    }
    if (productId && dose) {
      this.isLoading = true;
      this.productApiService
        .getProductBySlug(productId)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.getPaymentSession();
          })
        )
        .subscribe((data: any) => {
          const price = data.price.find((item: any) => item.med_id === dose);
          this.product = {
            id: data.id,
            title: data?.title,
            image: data?.image,
            doses: data?.doses,
            price: price
          };
        });
    }
  }

  getPaymentSession() {
    const temp_token = this.route.snapshot.queryParamMap.get('temp_token');
    const session_id = this.route.snapshot.queryParamMap.get('session_id');
    this.productApiService
      .getPaymentSession({ temp_token, session_id })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (response: any) => {
          if (response?.secret) {
            this.paymentData = response;
          }
        },
        error => {
          this.requestError = error?.error?.message;
        }
      );
  }

  getUserPaymentForm() {
    const dose_id = this.route.snapshot.queryParamMap.get('dose');
    const subscription = this.route.snapshot.queryParamMap.get('subscription');
    const userId = this.route.snapshot.queryParamMap.get('user_id');
    const surcharge = this.route.snapshot.queryParamMap.get('surcharge');
    const temp_token = this.route.snapshot.queryParamMap.get('temp_token');
    const quantity = this.route.snapshot.queryParamMap.get('quantity');
    const quiz_type = this.route.snapshot.queryParamMap.get('quiz_type');
    const returnUrl = `${environment.APP_ENDPOINT}/survey/questions?current_step=100&quiz_type=1`;
    this.requestError = null;
    const data = {
      dose_id,
      quiz_type,
      products: [
        {
          ...this.product,
          quantity: quantity ? +quantity : 1
        }
      ],
      subscription: subscription === 'true' ? true : false,
      return_url: returnUrl,
      user_id: userId ? +userId : null,
      promocode: this.promocode || null,
      surcharge: surcharge === 'true' ? true : false,
      temp_token
    };
    this.productApiService
      .getPaymentByToken(data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (response: any) => {
          if (response?.secret) {
            this.paymentData = response;
          }
        },
        error => {
          this.requestError = error?.error?.message;
        }
      );
  }

  removePromoCode(event: boolean) {
    this.promocode = null;
    this.reinitPaymentForm();
  }

  applyPromoCode(code: string) {
    this.promocode = code;
    this.reinitPaymentForm();
  }

  reinitPaymentForm() {
    this.paymentData = null;
    this.getUserPaymentForm();
  }
}
