import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { StepContactItemComponent } from '../../survey/components/step-contact-item/step-contact-item.component';
import { LoaderComponent } from 'src/app/shared/components/general/loader/loader.component';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GeneralApiService } from 'src/app/core/services/general-api.service';
import { finalize } from 'rxjs';
import { MessageService } from 'src/app/core/services/message.service';
import { ResponseMessages } from 'src/app/core/constants/config';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../auth/login/login.component';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-medication-feedback',
  standalone: true,
  imports: [
    StepContactItemComponent,
    LoaderComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule
  ],
  templateUrl: './medication-feedback.component.html'
})
export class MedicationFeedbackComponent implements OnInit, OnDestroy {
  private generalApiService = inject(GeneralApiService);
  private messageService = inject(MessageService);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);
  private router = inject(Router);
  feedbackForm = new FormGroup({
    'How are you doing with your medication?': new FormControl(null, [
      Validators.required
    ]),
    'What was your starting weight?': new FormControl(null, [
      Validators.required
    ]),
    'What is your current weight?': new FormControl(null, [
      Validators.required
    ]),
    'Are you experiencing any side effects?': new FormControl(null, [
      Validators.required
    ])
  });
  isLoading = false;
  isLoggedIn = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    const body = this.document.getElementsByTagName('body')[0];
    body.classList.add('hide-legitscript');
    this.isLoggedIn = this.authService.isAuthenticated();
  }

  ngOnDestroy(): void {
    const body = this.document.getElementsByTagName('body')[0];
    body.classList.remove('hide-legitscript');
  }

  sendFeedback() {
    if (this.isLoading) {
      return;
    }
    if (this.feedbackForm.valid && this.isLoggedIn) {
      const data = {
        questions: this.feedbackForm.value
      };
      this.isLoading = true;
      this.generalApiService
        .sendMedicationFeedback(data)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.messageService.setSuccessMessage(
            ResponseMessages.MEDICATION_FEEDBACK_SENT
          );
          this.router.navigate(['/']);
        });
    }
  }

  login() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '690px'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.isLoggedIn = this.authService.isAuthenticated();
      }
    });
  }
}
