import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogContainerComponent } from 'src/app/shared/components/general/dialog-container/dialog-container.component';

@Component({
  selector: 'app-maintenance-dialog',
  standalone: true,
  imports: [DialogContainerComponent, MatDialogModule],
  templateUrl: './maintenance-dialog.component.html',
  styleUrl: './maintenance-dialog.component.scss'
})
export class MaintenanceDialogComponent {}
