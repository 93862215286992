<div class="simple-header justify-content-center">
  <img src="assets/images/logo.svg" class="simple-header-logo" alt="" />
</div>
<div class="simple-page-content no-bottom bg-grey justify-content-center">
  <div class="bs-xs-40 bs-sm-60"></div>
  <div class="plan-content">
    <h3 class="text-center">Please select a plan that fits your needs</h3>
    <div class="bs-xs-40"></div>
    <div class="row row-xs">
      @for (item of plans?.plans; track $index) {
      <div class="col-lg-4 col-sm-12">
        <app-plan-item
          [ngClass]="{ active: item.id === selectedPlan?.id }"
          (updatePlan)="selectPlan($event)"
          [item]="item"
          [title]="plans.title"
          [product]="product"
        />
      </div>
      }
    </div>
    @for (item of plans?.disclamers; track $index) {
    <div class="text-sm mb-3" [innerHTML]="item.text"></div>
    }
  </div>
  <div class="bs-xs-40 bs-sm-60"></div>
  <app-step-contact-item />
</div>
<div [class.loading]="isLoading">
  <app-loader class="inner-loader" />
</div>
