import { formatDate } from '@angular/common';

export function chatSchedule() {
  const d = new Date();
  const time = formatDate(d, 'medium', 'en-US', 'EST');
  const day = new Date(time).getDay();
  const hour = new Date(time).getHours();
  console.log('Chat hour - ', hour);
  console.log('Chat day - ', day);
  const chatScript = document.createElement('script');
  chatScript.src = `https://widgets.leadconnectorhq.com/loader.js`;
  chatScript.setAttribute(
    'data-resources-url',
    'https://widgets.leadconnectorhq.com/chat-widget/loader.js'
  );
  const nightChat = '669ce4ccb12068b7e00d5ff8'; // from 17:01 to 8:59
  const dayChat = '669ac91cb120680d590d5e24'; // from 9:00 to 17:00
  if (day >= 1 && day <= 5 && hour >= 9 && hour < 17) {
    chatScript.setAttribute('data-widget-id', dayChat);
  } else {
    chatScript.setAttribute('data-widget-id', nightChat);
  }
  document.head.appendChild(chatScript);
}
