<div class="simple-header justify-content-center">
  <img src="assets/images/logo.svg" class="simple-header-logo" alt="" />
</div>
<div class="simple-page-content bg-grey justify-content-center">
  <div
    class="question-content text-center step-html-content"
    [class.loading]="isLoading"
  >
    <h3>Create New Password</h3>
    <div class="bs-xs-40"></div>
    <form (submit)="reset()" [formGroup]="resetForm">
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>New Password</mat-label>
        <input
          matInput
          [type]="isPasswordHidden ? 'password' : 'text'"
          formControlName="password"
        />
        @if (!isPasswordHidden) {
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility
        </mat-icon>
        }@else{
        <mat-icon
          class="pointer"
          (click)="isPasswordHidden = !isPasswordHidden"
          matSuffix
        >
          visibility_off
        </mat-icon>
        } @if (password?.invalid && (password?.dirty || password?.touched)) {
        <mat-error>
          @if (password?.errors?.['required']) { Field is required }
        </mat-error>
        }
      </mat-form-field>
      <div class="bs-xs-5"></div>
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>Repeat Password</mat-label>
        <input
          matInput
          [type]="isConfirmPasswordHidden ? 'password' : 'text'"
          formControlName="confirm_password"
        />
        @if (!isConfirmPasswordHidden) {
        <mat-icon
          class="pointer"
          (click)="isConfirmPasswordHidden = !isConfirmPasswordHidden"
          matSuffix
        >
          visibility
        </mat-icon>
        }@else{
        <mat-icon
          class="pointer"
          (click)="isConfirmPasswordHidden = !isConfirmPasswordHidden"
          matSuffix
        >
          visibility_off
        </mat-icon>
        } @if (confirmPassword?.invalid && (confirmPassword?.dirty ||
        confirmPassword?.touched)) {
        <mat-error>
          @if (confirmPassword?.errors?.['required']) { Field is required } @if
          (confirmPassword?.errors?.['matchField']) { The passwords don't match
          }
        </mat-error>
        }
      </mat-form-field>
      <div class="password-hint">
        <img src="assets/icons/password-information.svg" alt="" />
        <p class="text-sm lighter">
          {{ passwordHint }}
        </p>
      </div>
      <div class="bs-xs-40"></div>
      <button
        type="submit"
        class="app-button btn-primary btn-lg min-width"
        [disabled]="!resetForm.valid"
      >
        Create
      </button>
    </form>
  </div>
</div>
