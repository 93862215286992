<div class="simple-header justify-content-center">
  <img src="assets/images/logo.svg" class="simple-header-logo" alt="" />
</div>
<div class="simple-page-content no-bottom bg-grey">
  <div class="question-content text-center" [class.loading]="isLoading">
    <div class="bs-xs-40 bs-sm-60"></div>
    <h3 class="mb-4">How Are You Doing?</h3>
    <p>
      As part of our ongoing commitment to your health and wellness journey, we
      wanted to check in and see how you're doing with your medication.
      Understanding your progress and any concerns you may have is essential to
      us.
    </p>
    <div class="bs-xs-20"></div>
    @if (isLoggedIn) {
    <form [formGroup]="feedbackForm">
      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>How are you doing with your medication?</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="How are you doing with your medication?"
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>What was your starting weight?</mat-label>
        <input
          matInput
          type="text"
          formControlName="What was your starting weight?"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>What is your current weight?</mat-label>
        <input
          matInput
          type="text"
          formControlName="What is your current weight?"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="app-form-field">
        <mat-label>Are you experiencing any side effects?</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="Are you experiencing any side effects?"
        ></textarea>
      </mat-form-field>
      <div class="bs-xs-40"></div>
      <button
        type="submit"
        class="app-button btn-primary btn-lg min-width"
        [disabled]="!feedbackForm.valid"
        (click)="sendFeedback()"
      >
        Send
      </button>
    </form>
    }@else{
    <p class="text-center text-lg mt-4">
      Please
      <a (click)="login()" class="text-underline normal-weight pointer">
        login
      </a>
      to send the feedback.
    </p>
    }
  </div>
  <app-step-contact-item />
</div>
<div [class.loading]="isLoading">
  <app-loader class="inner-loader" />
</div>
